// @flow strict
import React from 'react';
import moment from 'moment';
import { Link } from 'gatsby';
import type { Edges } from '../../types';
import styles from './Feed.module.scss';

type Props = {
  edges: Edges,
};
const Feed = ({ edges }: Props) => (
  <div className={styles['feed']}>
    <h2 className={styles['feed__title']}>Hello</h2>
    <p className={styles['feed__subtitle']}>
      I'm AJ, a software engineer based in NYC. <br />I write about web
      technologies and becoming a better engineer.
    </p>
    <h3 className={styles['feed__feedtitle']}>Blog Posts</h3>
    {edges.map(edge => (
      <div className={styles['feed__item']} key={edge.node.fields.slug}>
        <img
          className={styles['feed__item-image']}
          alt={edge.node.frontmatter.socialImageAlt}
          src={edge.node.frontmatter.socialImage}
        />

        <h4 className={styles['feed__item-title']}>
          <Link
            className={styles['feed__item-title-link']}
            to={edge.node.fields.slug}
          >
            {edge.node.frontmatter.title}
          </Link>
        </h4>
        <p className={styles['feed__item-description']}>
          {edge.node.frontmatter.description}
        </p>
        <div className={styles['feed__item-meta']}>
          <time
            className={styles['feed__item-meta-time']}
            dateTime={moment(edge.node.frontmatter.date).format('MMMM D, YYYY')}
          >
            {moment(edge.node.frontmatter.date).format('MMMM YYYY')}
          </time>
          <span className={styles['feed__item-meta-divider']} />
        </div>
      </div>
    ))}
  </div>
);

export default Feed;
